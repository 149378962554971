import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import TemplateHero from '@/components/contents/shared/TemplateHero/TemplateHero'
import TemplateAbout from '@/components/contents/shared/TemplateAbout/TemplateAbout'
import Video from '@/components/contents/SeriousGamesTemplate/Video'
import TemplateGalleryTestimonials from '@/components/contents/shared/TemplateGalleryTestimonials/TemplateGalleryTestimonials'
import Footer from '@/components/Partials/Footer/Footer'
import GeneralAbout from '@/components/contents/shared/About/About'
import Contact from '@/components/contents/shared/Contact/Contact'
import { ContactWrapper } from '@/components/contents/ContactIndex/Contact.css'
import Prefooter from '@/components/Partials/Prefooter/Prefooter'
import * as UI from '@/components/UI'
import srcHeart from '@/assets/images/heart-red.png'
import srcCheeto from '@/assets/images/nobox-yellow.png'
import srcCloud from '@/assets/images/cloud-blue.png'
import getMetaImage from '@/utils/getMetaImage'

const SnowWrapper = styled.div`
  background: ${p => p.theme.colors.snow};
  color: ${p => p.theme.colors.maastricht};
`

const WhiteWrapper = styled.div`
  background: ${p => p.theme.colors.white};
  color: ${p => p.theme.colors.maastricht};
`

const SeriousGamesTemplate = ({ data }) => {
  const content = data?.content?.data

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
      background='maastricht'
      color='white'
    >
      <NavBar />
      <TemplateHero content={content} color='blue' />
      <SnowWrapper>
        <TemplateAbout content={content} color='blue' />
        <Video content={content} />
        <TemplateGalleryTestimonials content={content} color='blue' />
      </SnowWrapper>
      <WhiteWrapper>
        <GeneralAbout bottomColor='white' />
        <ContactWrapper>
          <UI.Parallax top='30%' left='-200px' force={1.3} offsetComp={30}>
            {offSet => (
              <UI.ImageLoose
                style={{
                  transform: `translateY(${offSet}px) translateZ(0)`,
                }}
                src={srcCloud}
                alt={content.meta_title}
                width='400'
                hiddenMobile
              />
            )}
          </UI.Parallax>
          <UI.Parallax top='0%' right='25%' force={1.1} offsetComp={10}>
            {offSet => (
              <UI.ImageLoose
                style={{
                  transform: `translateY(${offSet}px) translateZ(0) rotate(235deg)`,
                }}
                src={srcCheeto}
                alt={content.meta_title}
                width='400'
                hiddenMobile
              />
            )}
          </UI.Parallax>
          <UI.Parallax bottom='150px' right='180px' force={1.4} offsetComp={20}>
            {offSet => (
              <UI.ImageLoose
                style={{
                  transform: `translateY(${offSet}px) translateZ(0)`,
                }}
                src={srcHeart}
                alt={content.meta_title}
                width='320'
              />
            )}
          </UI.Parallax>
          <Contact content={content} />
        </ContactWrapper>
      </WhiteWrapper>
      <Prefooter content={content} />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query SeriousGamesTemplate($url: String!, $locale: String!) {
    content: prismicSeriousGame(uid: { eq: $url }, lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        hero_title
        hero_description
        hero_buttons {
          title
          link
        }
        hero_details {
          title
          duration_title
          duration
          button_title
          vacancy
          vacancy_title
          file {
            url
          }
        }
        hero_image {
          image {
            alt
            url
            localFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  src
                  sizes
                  originalName
                  srcSet
                }
              }
            }
          }
        }
        about_title
        about_body {
          html
        }
        about_sidebar_title
        about_sidebar_list {
          item
        }
        video_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560, quality: 40) {
                aspectRatio
                originalImg
                originalName
                sizes
                src
                srcSet
              }
            }
          }
        }
        video_id
        gallery {
          title
          image {
            localFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  originalImg
                  originalName
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        testimonials {
          testimonial {
            document {
              ... on PrismicTestimonial {
                data {
                  name
                  position
                  interview_text
                  interview_link
                  content {
                    html
                  }
                  image {
                    localFile {
                      childImageSharp {
                        fluid {
                          aspectRatio
                          originalName
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        prefooter_cards {
          card {
            document {
              ... on PrismicPrefootercard {
                data {
                  title
                  description
                  type {
                    uid
                    type
                    url
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SeriousGamesTemplate
